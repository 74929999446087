<template>
  <el-dialog :title="userItem.id ? '用户编辑' :  '用户新增'" :visible.sync="userAddorEditVisible" :close-on-click-modal="false" width="900px" append-to-body :before-close="handleClose">
    <!--新增-->
    <template v-if="!userItem.id">
      <el-form :model="ruleForm" :rules="rules" :validate-on-rule-change="false" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <div class="title">个人信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="name">
              <el-input class="w230" clearable v-model="ruleForm.name" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idNumber">
              <el-input class="w230" clearable v-model="ruleForm.idNumber" placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="contactPhone">
              <el-input class="w230" clearable v-model="ruleForm.contactPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属单位" prop="unitName">
              <el-input class="w230" v-model="ruleForm.unitName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属部门" prop="treeParams">
              <el-cascader
                class="w230"
                :key="keyvalue"
                :options=" treedata.length ? treedata[0].children: options"
                :props="defaultProps"
                ref="cascaderAddr"
                v-model="ruleForm.treeParams"
                :show-all-levels="false"
                clearable>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属角色" prop="roleId">
              <el-select class="w230" v-model="ruleForm.roleId" placeholder="请选择" clearable>
                <el-option
                  v-for="item in roleListConfig"
                  :label="item.roleName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="title">账号信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="账号" prop="accountNumber">
              <el-input class="w230" clearable @blur="usernameDed" v-model="ruleForm.accountNumber" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input class="w230" clearable show-password v-model="ruleForm.password" placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="comfirmPassword">
              <el-input class="w230" clearable show-password v-model="ruleForm.comfirmPassword" placeholder="请输入确认密码"></el-input>
            </el-form-item>
          </el-col>
          <div class="password">密码必须是8-16位大小写英文字母、数字、字符组合。
            在设置时，尽量不要出现连续数字的情况。</div>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </template>
    <!--编辑-->
    <template v-else>
      <el-form :model="editruleForm" :rules="editruleFormrules" ref="editruleForm" label-width="130px" class="demo-ruleForm">
        <div class="title">个人信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="name">
              <el-input class="w230" clearable v-model="editruleForm.name" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idNumber">
              <el-input class="w230" clearable v-model="editruleForm.idNumber" placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="contactPhone">
              <el-input class="w230" clearable v-model="editruleForm.contactPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属单位" prop="unitName">
              <el-input class="w230" clearable v-model="editruleForm.unitName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属部门" prop="treeParams">
              <el-cascader
                class="w230"
                :options="treedata"
                :props="defaultProps"
                ref="cascaderAddr"
                v-model="editruleForm.treeParams"
                :show-all-levels="false"
                clearable>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col v-if="userItem.accountNumber !== username" :span="12">
            <el-form-item label="所属角色" prop="roleId">
              <el-select class="w230" v-model="editruleForm.roleId" placeholder="请选择" clearable>
                <el-option
                  v-for="item in roleListConfig"
                  :label="item.roleName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('editruleForm')">确 定</el-button>
      </div>
    </template>

  </el-dialog>
</template>

<script>
  import { userAddOrEdit,usernameDeduplication, selectRole } from "@/api/userManage"
  import { IdentityCodeValid } from '../validatorRules'
  export default {
    name: "userAddorEditDilog",
    props: {
      userAddorEditVisible: {
        type: Boolean,
        default: false
      },
      treedata: {
        type: Array,
        default: () => {
          return []
        }
      },
      userItem: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      var validatePass = (rule, value, callback) => {
        const pwpattent = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/;
        if (pwpattent.test(value)) {
          callback();
        } else {
          callback(
            new Error("密码必须是8-16位大小写英文字母、数字、字符组合。")
          );
        }

      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        roleListConfig: [],
        userisAready: false, //用户是否已存在
        keyvalue: 0,
        options: [],
        editruleForm: {
          idNumber: '', //身份证号码
          name: '', //真实姓名
          contactPhone: '',//电话
          treeParams: [], //上级部门参数 要删除
          departmentId: '', //部门 id
          status: 0 ,//账号状态 0启用 1禁用
          unitName: '',//所属单位名字 需删除
          governmentId: '',//所属单位ID
          id: '', //数据id
          jurisdiction: '',
          jurisdictionName: '',
          roleId: ''
        },
        ruleForm: {
          idNumber: '', //身份证号码
          name: '', //真实姓名
          contactPhone: '',//电话
          accountNumber: '', //用户名
          password: '', //密码
          comfirmPassword: '', //确认密码
          treeParams: [], //上级部门参数 要删除
          departmentId: '', //部门 id
          status: 1 ,//账号状态 1启用 0禁用
          unitName: '',//所属单位名字 需删除
          governmentId: '',//所属单位ID
          jurisdiction: '',
          jurisdictionName: '',
          roleId: ''
        },
        editruleFormrules: {
          name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
          roleId: [{ required: true, message: '请选择所属角色', trigger: 'change' }],
          contactPhone:  [{ required: false, message: '请输入电话号码', trigger: 'blur' },
            {
              pattern: /^[1]([3-9])[0-9]{9}$/,
              message: '请正确输入11位手机号码',
              trigger: 'blur'
            }],
          idNumber: [{ required: false, validator: IdentityCodeValid, trigger: 'blur' }],
          treeParams: [
            { required: true, message: '请选择所属部门', trigger: ['blur', 'change'] }
          ],
        },
        rules: {
          name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
          roleId: [{ required: true, message: '请选择所属角色', trigger: 'change' }],
          contactPhone:  [{ required: false, message: '请输入电话号码', trigger: 'blur' },
            {
              pattern: /^[1]([3-9])[0-9]{9}$/,
              message: '请正确输入11位手机号码',
              trigger: 'blur'
            }],
          idNumber: [{ required: false, validator: IdentityCodeValid, trigger: 'blur' }],
          password: [{ required: true, validator: validatePass, trigger: 'blur' }],
          comfirmPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }],
          accountNumber:[{ required: true, message: '请输入用户名', trigger: 'blur' }],
          treeParams: [
            { required: true, message: '请选择所属部门', trigger: ['blur', 'change'] }
          ],
        },
        defaultProps: {
          value: 'id',
          label: 'label',
          checkStrictly: true
        },
        username: ''
      }
    },
    mounted() {
      this.selectRoleData()
      Object.keys(this.editruleForm).forEach((item) => {
        this.editruleForm[item] = this.userItem[item]
      })
      this.editruleForm.treeParams = this.getparentId( this.treedata, this.editruleForm.departmentId)
      //编辑
      this.editruleForm.unitName = JSON.parse(localStorage.getItem('cad_userInfo')).unitName


      // this.resetForm('ruleForm')
      //取所属单位
      let cad_userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
      let { unitId, unitName, jurisdiction, jurisdictionName,username  } = cad_userInfo
      //新增
      this.username = username //是否显示所属角色需要
      this.ruleForm.governmentId = unitId
      this.ruleForm.unitName = unitName
      this.ruleForm.jurisdiction = jurisdiction
      this.ruleForm.jurisdictionName = jurisdictionName

      // this.editruleForm.unitId = unitId
      // this.editruleForm.unitName = unitName

      setInterval(function() {// 解决级联选择器不点击圆点选中元素问题
        document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function() {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
        });
      }, 1000);
    },
    methods: {
      async selectRoleData() {
        const res = await selectRole()
        if(res.state === 0) {
          this.roleListConfig = res.data
        }
      },
      //递归查询父级ID
      getparentId(treeData,parentId ) {
        const find = (classifyList, id) => {
          let temp = [id];
          let forFn = function (arr, id) {
            for (let i = 0; i < arr.length; i++) {
              let item = arr[i];
              if (item.children) {
                let data1 = item.children.find((item1) => item1.id === id); //当前这项是否相等
                if (data1) {
                  temp.unshift(item.id); //存在 push 并结束
                  forFn(classifyList, item.id);
                  break;
                } else {
                  forFn(item.children, id); //不存在递归查询
                }
              }
            }
          };
          forFn(classifyList, id);
          return temp;
        };
        return find(treeData, parentId)
      },
      handleClose() {

        if(this.$refs.ruleForm)  this.resetForm('ruleForm')
        this.$emit('update:userAddorEditVisible', false)
      },
      //提交表单
      submitForm(formName) {
       if(!this.userisAready) {
         this.$refs[formName].validate((valid) => {
           if (valid) {
             let params = {}
             if(this.userItem && this.userItem.id) { //编辑
               params = JSON.parse(JSON.stringify(this.editruleForm))
               params.departmentId = params.treeParams[params.treeParams.length-1]
               Reflect.deleteProperty(params, 'treeParams')
               Reflect.deleteProperty(params, 'unitName')
               Reflect.deleteProperty(params, 'comfirmPassword')
             }else { //新增
               params = JSON.parse(JSON.stringify(this.ruleForm))
               params.departmentId = params.treeParams[params.treeParams.length-1]
               Reflect.deleteProperty(params, 'treeParams')
               Reflect.deleteProperty(params, 'unitName')
             }
             this.userAddOrEditPost(params)
           } else {
             console.log('error submit!!');
             return false;
           }
         });
       }else {
         this.$message.error('当前用户名已经存在')
       }
      },
      //重置表单
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      //新增、编辑用户
      async userAddOrEditPost(params) {
        const res = await userAddOrEdit(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          if(this.$refs.ruleForm)  this.resetForm('ruleForm')
          //请求成功，刷新页面
          this.$emit('reloadPage')
          this.$emit('update:userAddorEditVisible', false)
        }
      },
    //  用户名去重
    async  usernameDed() {
        const res = await usernameDeduplication({userName: this.ruleForm.accountNumber})
        if(res.data) {
          this.$message.error(res.data)
          this.userisAready = true
        }else {
          this.userisAready = false
        }
    }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 230px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .password {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
    margin-left: 130px;
  }
</style>
